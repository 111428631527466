import React from 'react';
import logo from '../../static/images/logo_all.jpg'

export default class ClientCarousel extends React.Component {
  render() {
    return (
      <>
        <div>
          <center>
            <h2 style={{ marginBottom: '40px' }}>Our clients</h2>
            <div style={{ paddingBottom: '100px', paddingTop: '100px', backgroundColor: 'white' }} >
              <img src={logo} alt="nice" style={{ maxWidth: '80%' }} />
            </div>
          </center>
        </div>
      </>
    );
  }
}
