import React from 'react';
import _ from 'lodash';
import Slider from 'react-slick';

import {htmlToReact, withPrefix, Link} from '../utils';

export default class SectionReviews extends React.Component {

    state = {
      maxHeight : 0
    }
    componentDidMount() {
      setTimeout(() => {
        let cards = document.getElementsByClassName("clintsCards");
        cards = [...cards];
        let maxHeight = 0;
        cards.forEach(element => {
          if(element.offsetHeight > maxHeight){
            maxHeight = element.clientHeight
          }
        });
        this.setState({maxHeight: maxHeight});
      }, 300);
    }

    settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 3000,
      fadeIn: false,
      autoplay: true,
      pauseOnHover: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} className={'block reviews-block bg-' + _.get(section, 'background', null) + ' outer'}>
              <div className="block-header inner-small">
                {_.get(section, 'title', null) && (
                <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                )}
                {_.get(section, 'subtitle', null) && (
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle', null))}
                </p>
                )}
              </div>
              {_.get(section, 'reviews', null) && (
              <div className="inner">
                {/* <div className="grid"> */}
                <Slider {...this.settings}>
                  {_.map(_.get(section, 'reviews', null), (review, review_idx) => (
                  <blockquote key={review_idx} className="cell review full-width">
                    <div className="card">
                      <p className="review-text clintsCards" style={{minHeight: this.state.maxHeight + 15}}>{htmlToReact(_.get(review, 'content', null))}</p>
                      <footer className="review-footer">
                        {_.get(review, 'avatar', null) && (
                        <img className="review-avatar" src={withPrefix(_.get(review, 'avatar', null))} alt={_.get(review, 'avatar_alt', null)}/>
                        )}
                        <Link to={_.get(review, 'linkedIn_url', null) || '#'} target={_.get(review, 'linkedIn_url', null) ? '_blank' : ''} >
                          <cite className="review-author">
                            {_.get(review, 'author', null)}
                            <span className="client-role color-orange">{_.get(review, 'company_name', null)}</span>
                            <span className="client-role">{_.get(review, 'role', null)}</span>
                          </cite>
                        </Link>
                      </footer>
                    </div>
                  </blockquote>
                  ))}
                </Slider>
                {/* </div> */}
              </div>
              )}
            </section>
        );
    }
}
