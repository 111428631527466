import React from 'react';
import _ from 'lodash';
import Slider from 'react-slick';

import {htmlToReact, getPages, Link, withPrefix} from '../utils';
import BlogPostFooter from './BlogPostFooter';

export default class SectionPosts extends React.Component {
    state = {
      maxHeight : 0
    }
    componentDidMount() {
      setTimeout(() => {
        let cards = document.getElementsByClassName("tmp");
        cards = [...cards];
        let maxHeight = 0;
        cards.forEach(element => {
          if(element.offsetHeight > maxHeight){
            maxHeight = element.clientHeight
          }
        });
        this.setState({maxHeight: maxHeight});
      }, 300);
    }
    render() {
        let section = _.get(this.props, 'section', null);
        let display_posts = _.orderBy(getPages(this.props.pageContext.pages, '/blog'), 'frontmatter.date', 'desc');
        let recent_posts = display_posts;
        const settings = {
          dots: false,
          infinite: true,
          speed: 1000,
          autoplaySpeed: 4000,
          fadeIn: false,
          autoplay: false,
          pauseOnHover: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        };
        return (
            <section id={_.get(section, 'section_id', null)} className={'block posts-block bg-' + _.get(section, 'background', null) + ' outer'}>
              {/* <div className="block-header inner-small">
                {_.get(section, 'title', null) && (
                <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                )}
                {_.get(section, 'subtitle', null) && (
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle', null))}
                </p>
                )}
              </div>
              <div className="inner">
                <div className="grid post-feed">
                  {_.map(recent_posts, (post, post_idx) => (
                  <article key={post_idx} className="cell post">
                    <div className="card">
                      {_.get(post, 'frontmatter.thumb_image', null) && (
                      <Link className="post-thumbnail" to={withPrefix(_.get(post, 'url', null))}>
                        <img src={withPrefix(_.get(post, 'frontmatter.thumb_image', null))} alt={_.get(post, 'frontmatter.thumb_image_alt', null)} />
                      </Link>
                      )}
                      <div className="post-body">
                        <header className="post-header">
                          <h3 className="post-title"><Link to={withPrefix(_.get(post, 'url', null))}>{_.get(post, 'frontmatter.title', null)}</Link></h3>
                        </header>
                        <div className="post-excerpt">
                          <p>{_.get(post, 'frontmatter.excerpt', null)}</p>
                        </div>
                        <BlogPostFooter {...this.props} page={post} date_type={'short'} />
                      </div>
                    </div>
                  </article>
                  ))}
                </div>
              </div> */}
              <div className="block-header inner-small">
                {_.get(section, 'title', null) && (
                <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                )}
                {_.get(section, 'subtitle', null) && (
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle', null))}
                </p>
                )}
              </div>
              <Slider {...settings}>
                {_.map(recent_posts, (post, post_idx) => (
                <Link to={withPrefix(_.get(post, 'url', null))} className='no-underline' >
                  <article key={post_idx} className="cell post">
                    <div className="card tmp" style={{minHeight: this.state.maxHeight + 15}}>
                      {_.get(post, 'frontmatter.thumb_image', null) && (
                      <Link className="post-thumbnail" to={withPrefix(_.get(post, 'url', null))}>
                        <img src={withPrefix(_.get(post, 'frontmatter.thumb_image', null))} alt={_.get(post, 'frontmatter.thumb_image_alt', null)} />
                      </Link>
                      )}
                      <div className="post-body">
                        <header className="post-header">
                          <h3 className="post-title"><Link to={withPrefix(_.get(post, 'url', null))}>{_.get(post, 'frontmatter.title', null)}</Link></h3>
                        </header>
                          <div className="post-excerpt">
                            <p>{`${_.get(post, 'frontmatter.excerpt', null)}`} &hellip;</p>
                          </div>
                        <BlogPostFooter {...this.props} page={post} date_type={'short'} />
                      </div>
                    </div>
                  </article>
                </Link>
                ))}
              </Slider>
            </section>
        );
    }
}
