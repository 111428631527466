import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';

import arrowIcon from '../../static/images/up_arrow1.png'

const BackToTop = () => {
    const checkScrollTop = () => {
        let btn = document.getElementById("backToTopBtn");
        if(btn) {
            if(document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
                btn.style.display = "block";
            } else {
                btn.style.display = "none";
            }
        }
    }
    if(typeof window !== 'undefined'){
        window.addEventListener('scroll', checkScrollTop);
    }
    return (
        <>
            <span id="backToTop"></span>
            <span id="backToTopBtn" title="Go to Top" onClick={() => scrollTo('#backToTop')} onKeyDown={() => scrollTo('#backToTop')}  aria-hidden="true" >
                <img src={arrowIcon} alt="top" />
            </span>
        </>
    )
}

export default BackToTop;

